import { render } from '@testing-library/react';
import React, { Component, useEffect, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom';
import { GeoserverGetCapabilities } from '../../services/geoserver/GeoserverCapabilities';
// import { CapabilitiesJSON } from '../../services/geoserver/Capabilities';

export default function MenuCatalogComponent() {

  const [layers, setLayers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    const geoserver = new GeoserverGetCapabilities();
    await geoserver.getCapabilities().then((capabilitiesResponse) => {
      const layersResponse = geoserver.getLayers(capabilitiesResponse);
      const groupsResponse = geoserver.groupLayers(layersResponse);
      // let layersResponse = geoserver.getLayers(CapabilitiesJSON);

      layersResponse.map(layer => {
        layer.workspace = layer.Name[0].split(':')[0].split('.')[1] ? layer.Name[0].split(':')[0].split('.')[1] : layer.Name[0].split(':')[0].split('.')[0];
        layer.group = layer.Name[0].split(':')[0].split('.')[1] ? layer.Name[0].split(':')[0].split('.')[0] : '';
        layer.label = layer.Name[0].split(':')[1];
      });

      layersResponse.sort(function (a, b) {
        return a.group.localeCompare(b.group);
      });

      const workspaces_sorted = Object.keys(groupsResponse)
      workspaces_sorted.push('1');

      workspaces_sorted.sort(function (a, b) {
        return a.localeCompare(b);
      });

      setWorkspaces(workspaces_sorted);
      setLayers(layersResponse);
    });
  }

  const handlerMenu = (workspace) => {
    if (menu == workspace)
      setMenu(null);
    else
      setMenu(workspace);
  }

  const CalalogGroup = (props) => {

    if (props.workspace == '1') {
      return null
    }

    return (
      <>
        <li className={menu == props.workspace ? 'active open' : ''}  >
          <a onClick={() => handlerMenu(props.workspace)} href="javascript:void(0);" className="menu-toggle  waves-effect waves-block">
            <i className="zmdi zmdi-caret-down"></i><span>{props.workspace.replaceAll('_', ' ')}</span>
          </a>
          <ul className="ml-menu" style={{ display: menu == props.workspace ? 'block' : 'none' }}>
            <CatalogItem workspace={props.workspace} layers={props.layers} />
          </ul>
        </li>
      </>
    );
  }

  const CatalogItem = (props) => {
    const layersFiltered = props.layers.filter((layer) => {
      return String(layer.workspace) == String(props.workspace)
    });

    return (
      <>
        {layersFiltered.map(layer => <li key={Math.random()}> <Link to={'/map'} title={layer.Abstract} state={{ currentLayer: layer }} className="waves-effect waves-classic"> <span> {layer.Title} </span> </Link></li>)}
      </>
    );
  }

  const getGroup = (_workspace) => {
    return !_workspace.split('.')[1] ? "" : _workspace.split('.')[0];
  }

  const getWorkspace = (_workspace) => {
    return !_workspace.split('.')[1] ? _workspace.split('.')[0] : _workspace.split('.')[1];
  }

  return (
    <>
      <div className="menu">
        <ul className="list">
          <li className="user-info" style={{ height: '40px' }}>  <span style={{ fontWeight: '', color: 'white' }}> CATÁLOGOS </span> </li>
          {
            workspaces.map((workspace, i) => {
              return getGroup(workspace) == getGroup(workspaces[((i - 1) < 1 ? 0 : (i - 1))])
                ? <CalalogGroup key={Math.random()} workspace={getWorkspace(workspace)} layers={layers} />
                : <> <li className="header upper">  {getGroup(workspace).replaceAll('_', ' ')}  </li>  <CalalogGroup key={Math.random()} workspace={getWorkspace(workspace)} layers={layers} /> </>
            })
          }
          <li className="header">IDE PARCEIROS</li>
          <li ><Link to='https://idesisema.meioambiente.mg.gov.br/' target={'_blank'} className=" waves-effect waves-block"><i className="zmdi zmdi-globe"></i><span>IDE-Sisema</span> <span className='text-text-muted' style={{ fontSize: 11 }}> Meio Ambiente </span> </Link></li>
          <li ><Link to='http://bhmap.pbh.gov.br/' target={'_blank'} className=" waves-effect waves-block"><i className="zmdi zmdi-globe"></i><span>IDE BHGEO</span> <span className='text-text-muted' style={{ fontSize: 11 }}> RMBH </span> </Link></li>
          <li className="header">CONTEÚDO</li>
          <li ><Link to='documents' className=" waves-effect waves-block"><i className="zmdi zmdi-file"></i><span>Documentos e Mapas</span> </Link></li>
          <li ><Link to="/downloads" className=" waves-effect waves-block"><i className="zmdi zmdi-download"></i><span>Downloads</span> </Link></li>

        </ul>
      </div>
    </>
  );

}