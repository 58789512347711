import React from "react";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import ContentBox from "../components/ContentBox";
import Dashboards from "../components/Dashboards";
import Staff from "./Staff";

export default function Home() {

  return (
    <>
      <section className='content' style={{ marginBottom: '-40px' }}>
        <div className='block-header'>
          <div className='row'>
            <div className='col-lg-7 col-md-6 col-sm-12'>
              <h2>BEM-VINDO À IEDE-MG</h2><small className='text-muted'>INFRAESTRUTURA ESTADUAL DE DADOS ESPACIAIS</small>
            </div>
          </div>
        </div>
      </section>

      <div className="col-12">
        <Dashboards />
      </div>

      <ContentBox>
        <div className="row">
          <div className="col-md-6">
            <h5> <i className="zmdi zmdi-globe"> </i> IEDE </h5>
            <p className="text-justify">
              A Infraestrutura Estadual de Dados Espaciais - IEDE, gerida pela Fundação João Pinheiro, foi instituída no
              âmbito
              do
              Poder Executivo pelo Decreto Estadual Nº 45.394, de 10/06/2010.
            </p>
            <blockquote>
              <p className="text-justify">Acesse a Lei nº 5.399, de 12 de dezembro de 1969, que cria a Fundação João Pinheiro.</p>
            </blockquote>
          </div>
          <div className="col-md-6">
            <h5> <i className="zmdi zmdi-pin"></i> Fale Conosco</h5>
            <p className="text-justify">
              Fundação João Pinheiro - Pampulha
              <br /> Endereço: Alameda das Acácias, 70 - São Luiz CEP: 31.275-150 | Belo Horizonte/MG
              <br /> Telefone: +55 (31) 3448-9475
              <br /> Email: iede@fjp.mg.gov.br
            </p>
          </div>
        </div>
        {/* <hr /> */}

        <div style={{ margin: 0 }}>
          <div className="row">
            <img src="assets/images/lg/map-mg.png" className="img-fluid w-100" />
          </div>
        </div>
        <br />

      </ContentBox>
      <ContentBox>
        <h4>A INFRAESTRUTURA ESTADUAL DE DADOS ESPACIAIS - IEDE</h4>
        <p className="text-justify">
          Gerida pela Fundação João Pinheiro, foi instituída no âmbito do Poder Executivo pelo Decreto Estadual Nº 45.394, de 10/06/2010.
        </p>

        <h5>OBJETIVOS DA IEDE</h5>
        <p className="text-justify">
          <ol>
            <li>
              Promover o adequado ordenamento na geração, armazenamento, acesso, compartilhamento, disseminação e uso dos dados geoespaciais de origem estadual, em proveito do desenvolvimento de Minas Gerais;
            </li>
            <li>
              Promover a utilização dos padrões e normas homologados pela Comissão Nacional de Cartografia na produção dos dados geoespaciais pelos órgãos e entes públicos estaduais;
            </li>
            <li>
              Evitar a duplicidade de ações e o desperdício de recursos na obtenção de dados geoespaciais pela Administração Pública, por meio da divulgação dos metadados relativos a esses dados disponíveis nos órgãos e entes públicos estaduais.
            </li>
          </ol>
        </p>
        <h5>
          MARCOS TEÓRICOS DO DECRETO
        </h5>
        <h6>
          - DADO OU INFORMAÇÃO GEOESPACIAL:
        </h6>
        <p className="text-justify">
          aquele que se distingue essencialmente pelo componente espacial, que associa a cada entidade ou fenômeno uma localização na Terra, traduzida por sistema geodésico de referência, em dado instante ou período de tempo, podendo ser derivado, dentre outras fontes, das tecnologias de levantamento, inclusive as associadas a sistemas globais de posicionamento apoiados por satélites, bem como de mapeamento ou de sensoriamento remoto;
        </p>

        <h6>- METADADOS DE INFORMAÇÕES GEOESPACIAIS:</h6>
        <p className="text-justify">
          conjunto de informações descritivas sobre os dados, incluindo as características do seu levantamento, produção, qualidade e estrutura de armazenamento, essenciais para promover a sua documentação, integração e disponibilização, bem como possibilitar a sua busca e exploração;
        </p>
        <h6>
          - INFRAESTRUTURA ESTADUAL DE DADOS ESPACIAIS - IEDE:
        </h6>
        <p className="text-justify">
          conjunto integrado de tecnologias, políticas, mecanismos e procedimentos de coordenação e monitoramento, padrões e acordos, necessários para facilitar e ordenar a geração, o armazenamento, o acesso, o compartilhamento, a disseminação e o uso dos dados geoespaciais de origem estadual.
        </p>

        <h5>
          OPERACIONALIDADE DA IEDE
        </h5>

        <h6>
          - REDE MINEIRA DE DISTRIBUIDORES DE DADOS GEOESPACIAIS - RMDDG:
        </h6>
        <p className="text-justify">
          sistema de servidores de dados distribuídos na rede mundial de computadores, capaz de reunir eletronicamente produtores, gestores e usuários de dados geoespaciais, com vistas ao armazenamento, compartilhamento e acesso a esses dados e aos serviços relacionados; e
        </p>
        <h6>
          - PORTAL MINEIRO DE DADOS GEOESPACIAIS - PMDG:
        </h6>
        <p className="text-justify">
          portal que disponibiliza os recursos da RMDDG, para publicação ou consulta sobre a existência de dados geoespaciais, bem como para o acesso aos serviços relacionados existentes.
        </p>
        <p className="text-justify">
          Observa-se que o Decreto Estadual estabelece que dados estatísticos podem, a critério do órgão produtor, ser considerados como dados geoespaciais; ou seja, se ao dado estatístico for agregado o componente espacial, ele passa a ser um dado geoespacial. O Decreto caracteriza, ainda, que o dado geoespacial oficial é aquele homologado pelos órgãos competentes da Administração Pública.
        </p>
        <br />
        <h6>
          CAMADAS(Layers):
        </h6>
        <p className="text-justify">
          Camadas, também denominadas de camadas web, são coleções lógicas de dados geográficos que são utilizados para criar mapas e cenas; elas também são a base da análise geográfica.
        </p>
        <div className="container">
          <img src="assets/images/lg/camadas.png" className="mx-auto d-block" />
        </div>
        <h6>
          ESPELHO PADRÃO:
        </h6>
        <p className="text-justify">
          Cada camada da IEDE possui um espelho (nome) com um padrão. É formado através da composição ao qual os dois primeiros dígitos se referem ao acrônimo do Estado; os subsequentes se relacionam ao nome da camada; os seguintes indicam o órgão ou entidade responsável pelo dado; na sequência apontam a geometria; na sequencia o mês e por fim, os últimos digitos são o ano do dado.
        </p>
        <div className="container">
          <img src="assets/images/lg/espelho.svg" className="mx-auto d-block" />
        </div>
        <br />
      </ContentBox>
    </>
  )

}