import React, { useEffect, useState } from 'react'
import { WMSTileLayer, MapContainer, TileLayer, LayerGroup, Map, LayersControl, ZoomControl } from 'react-leaflet';
import { GeoserverGetCapabilities } from '../../services/geoserver/GeoserverCapabilities';
const { BaseLayer } = LayersControl;

export default function BaseLayersComponent({ layer }) {
  const [base, setBase] = useState();
  const getCapabilities = new GeoserverGetCapabilities();

  useEffect(() => {
    const hasBase = getCapabilities.getLayerKeywordValues(layer, 'baselayer')[0];
    if (hasBase)
      setBase(hasBase);
    else
      setBase('googlesatelite');
  }, [layer]);

  return (
    <>
      <BaseLayer name={'OpenStreetMap'} checked={base == 'openstreetmap' ? true : false} key={'openstreetmap'}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </BaseLayer>
      <BaseLayer name='OpenTopoMap' checked={base == 'opentopomap' ? true : false} key={'opentopomap'}>
        <TileLayer
          attribution='&copy;<a href="https://opentopomap.org/#map=6/-18.969/-45.000">OpenTopoMap</a>'
          url="https://tile.opentopomap.org/{z}/{x}/{y}.png"
        />
      </BaseLayer>
      <BaseLayer checked={base == 'googlesatelite' ? true : false} name='Google Satélite' key={'googlesatelite'}>
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          attribution='&copy;<a href="https://maps.google.com">GoogleSatélite</a>'
          maxZoom={20}
          subdomains={['mt1', 'mt2', 'mt3']}
        />
      </BaseLayer>

      <BaseLayer name={'Vazio'} checked={false} key={'vazio'}>
        <TileLayer
          attribution=''
          url=""
        />
      </BaseLayer>
    </>
  )
}
